import { Component, Output, EventEmitter, OnInit, Input, Injector  } from '@angular/core';
import { NgFor } from '@angular/common'; 
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';
import { ContractAdminService } from 'src/app/services/contract-admin/contractadmin.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { FormsModule, ValidationErrors } from '@angular/forms';
import { NgbAccordionModule, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { HttpResponseCheck } from 'src/app/services/httpresponse-check/htttpResponseCheck.service';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';


interface ICostTarget {
  mp: number,
  lct: number,
  measuremenT_PERIOD: number,
  loW_COST_THRESHOLD:number
}

interface IMP{
  code: number,
  value: number
}

interface IQualityDetails {
  mp: number,
  qmn: number,
  pmt: number,
  blp: number,
  ti: string,
  measuremenT_PERIOD: number,
  qualitY_METRIC_NUMBER: number,
  qM_THRESHOLD: number,
  baselinE_PERFORMANCE: number,
  qM_TIER_IND: string

}

interface IContractdata {
  contract_id: string,
  client_id: string,
  tin: string,
  state: string,
  market: string,
  county: string,
  eoc_id: string,
  contract_stat:string,
  contract_begin:string,
  contract_end:string,
  low_outlier_value: string,
  high_outlier_value: string,
  baseline_cost_target: string,
  gain_sharing_pct: string,
  shared_opp_limit_pct: string,
  algorithm_version: string,
  precontracting_dbname: string,
  obh_ind: string,
  tin_name: string,
  historical_tin_name: string,
  config_version: string,
  precontract_dbname:string
  }
  

  
@Component({
  selector: 'app-contractadmin',
  templateUrl: './contractadmin.component.html',
  styleUrls: ['./contractadmin.component.css'],
})



export class ContractadminComponent  implements OnInit
{ 
	csdmodel: NgbDateStruct;
	cedmodel: NgbDateStruct;

  

  usermodel = {
    columns: [
	 {label: 'Action', id: 'action', datatype: 'text'},
	 {label: 'Measurement Period', id: 'hdrmeasurementperiod', datatype: 'text'},
	 {label: 'Low Cost Threshold', id: 'hdrlowcostthreshold', datatype: 'text'},
	]
}

usermodelQD = {
  columns: [
 {label: 'Action', id: 'action', datatype: 'text'},
 {label: 'Measurement Period', id: 'hdrmeasurementperiod', datatype: 'text'},
 {label: 'Quality Metric #', id: 'hdrqmnumber', datatype: 'text'},
 {label: 'Performance Measure Threshold', id: 'hdrperfmeasurethreshold', datatype: 'text'},
 {label: 'Baseline Performance', id: 'hdrblperformance', datatype: 'text'},
 {label: 'Tiering Indicator', id: 'hdrtiering', datatype: 'text'},

]
}
  @Output() mydata = new EventEmitter <string> (); 

  name: string ="Data pushed from storage ";  
  PostData() 
  {  
    //console.log("Post Data called", this.name);
    this.mydata.emit(this.name);  
  }
  
  submit(f: any)
  {
    //console.log(f); 
  }

  public tin:any;
  public tinname:any;
  public contractStartDate:any;
  public contractEndDate:any;
  public state:any;
  public eoc:any;
  public pcdb:any;
  public reportduedate:any;
  public county:any;
  public market:any;
  public lowoutliervalue:any;
  public highoutliervalue:any;
  public baselinecosttarget: any;
  public gainsharingpercent:any;
  public sharedoppertunitypercent:any;
  public previousreportcycleid:any;
  public reportcycleid:any;
  public provider:any;
  public indicator:any;
  public reporttype:any;
  public contractID:any;
  public selectedState: string = "";
  public selectedProvider:string = "";
  public selectedEOC:string = "";
  public selectedPCDB:string = "";
  public selectedIndicator:string = "";
  public selectedMPCT:string = "";
  public selectedMPQD:string = "";
  public selectedTier:string = "";

  public selectedTIN:string = "";
  public selectedTINName:string = "";

  public show:boolean = false;
  addReturnMessage:string ="";
  Isvalidtagname:boolean=true;
  disabled=false;
  public lowcostthreshold:any;
  public measurementperiod:any;
  public measurementperiodQD:any;
  ctCollection: ICostTarget[] = [];
  public mpArrayObject = [];
  mpCollection: IMP[]=[];


  public qualitymetricnumber:any;
  public qualitymetricthreshold:any;
  public performancemeasurethreshold:any;
  public baselineperformance:any;
  qdCollection: IQualityDetails[] = [];
  public MPList:any =[];
  public algorithmversion:any;
  public configversion:any;
  public precontractdbname:any;
  public editmode: any;
  public id:number;
  public contractdata: any;
  public contractdatact: [];
  public contractdataqd: [];
  isTextInputVisible: boolean = false;


  constructor(
    public authservice: AuthorizationService, 
    public localStorage:LocalStorageService,
    private storage_token: LocalStorageService,
    private router: Router,
    private toastr: ToastrService,
    private contractadminservice: ContractAdminService,
    private _parserFormatter: NgbDateParserFormatter,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) 
  {

    this.route.queryParams.subscribe(params=>{
      this.id = params['id'];
    })

    if(this.id>0)
      {
        this.spinner.show();
        this.editmode =true;
        this.storage_token.set("CONTRACT_ID", this.id);
        this.storage_token.set('contract_id', this.id);

        this.authservice.LoadContractData().subscribe((response: any) => 
        {
          this.contractdata = response;
          this.populatecontractdata();
         });
        
        this.authservice.LoadContractQTData().subscribe((response: any) => 
        {
          this.qualitydetailstable = response.$values;
          this.populateQualitydata();
        });
        
        this.authservice.LoadContractCTData().subscribe((response: any) => 
        {
          this.costtargettable = response.$values;
          this.populateCostData();
        });
        // this.populate();
      }
      else
      {
        this.storage_token.set("CONTRACT_ID", 0);
        this.editmode =false;
      }

    // this.route.queryParams.subscribe(params => {

    //   this.id = params['id'];
    //   if(this.id > 0 )
    //     {
    //       this.editmode =true;
    //       this.storage_token.set("CONTRACT_ID", this.id);
    //       this.collectData();
    //       setTimeout(() => 
    //         {
              
    //           this.populate();
    //         }, 5000 );

            
    //     }
    //     else
    //     {
    //       this.storage_token.set("CONTRACT_ID", 0);
    //       this.editmode =false;
    //     }
    // });
  }
  

 

  ngOnInit(): void 
  {
    
  };

  


  ngLoad():void
  { 
    
  };
  stateList: any = this.authservice.getStateList();
  providerList: any = this.authservice.getProviderList();
  EOCList: any = this.authservice.getEOCList();
  PCDBList:any =this.authservice.getPCDBList();
  IndicatorsList: any = this.authservice.getIndicatorsList();
  ReporttypeList:any = this.authservice.getReportTypeList();
  // tinList:any = this.authservice.getTINSList();
  // tinnamesList:any = this.authservice.getTINNamesList();
  qmTierList:any =this.authservice.geQMTiersList();

  collectData()
  {
    this.storage_token.set('contract_id', this.id);
    this.authservice.LoadContractData();
    this.authservice.LoadContractQTData();
    this.authservice.LoadContractCTData();
  }

  populatecontractdata()
  {
      //Section - Contract Details
      //this.contractdata = this.authservice.getcontractData();
      //console.log(this.contractdata);
      if(this.contractdata.contracT_ID === 0 )
      {
          this.toastr.error("Not a Valid Contract ID.");
          this.router.navigateByUrl("/reports?program=ContractList");
      }
      this.county = this.contractdata.county;
      this.market= this.contractdata.market;
      this.tinname= this.contractdata.tiN_NAME;
      this.tin= this.contractdata.tin;
      this.selectedState = this.contractdata.state;
      this.selectedEOC = this.contractdata.eoC_ID;
      if(this.contractdata.obH_IND === 0)
        {
          this.selectedIndicator= "OBH";
        }
        else        
        {
          this.selectedIndicator= "NON-OBH";
        }

      if(this.contractdata.cntrcT_BEGIN != undefined)
      {
        this.csdmodel = this._parserFormatter.parse(this.contractdata.cntrcT_BEGIN.toString());
      }
      if(this.contractdata.cntrcT_END != undefined)
      {
        this.cedmodel = this._parserFormatter.parse(this.contractdata.cntrcT_END.toString());

      }
      this.lowoutliervalue= this.contractdata.loW_OUTLIER_VALUE;
      this.highoutliervalue= this.contractdata.higH_OUTLIER_VALUE;
      this.baselinecosttarget= this.contractdata.baselinE_COST_TARGET;
      this.gainsharingpercent= this.contractdata.gaiN_SHARING_PCT;
      this.sharedoppertunitypercent= this.contractdata.shareD_OPP_LIMIT_PCT;
      this.configversion= this.contractdata.confiG_VERSION;
      this.selectedPCDB=this.PCDBList.filter(item =>item.value === this.contractdata.precontractinG_DBNAME).map(item => item.value);
      if(this.selectedPCDB.length == 0)
        {
          this.selectedPCDB = "Not Found";
          this.isTextInputVisible=true;
          this.precontractdbname=this.contractdata.precontractinG_DBNAME;
        }

      this.algorithmversion= this.contractdata.algorithM_VERSION;
       //Reqd. to populte the MP dropdown based on the dates
      this.populateMPList();
  }

  populateCostData()
  {
    this.costtargettable.forEach(item => 
      {
          this.ctCollection.push(
            {
             lct: item.loW_COST_THRESHOLD,
             mp:item.measuremenT_PERIOD,
             loW_COST_THRESHOLD:item.loW_COST_THRESHOLD ,
             measuremenT_PERIOD:item.measuremenT_PERIOD
            });
      });
      this.costtargettable = this.ctCollection;
  }

  populateQualitydata()
  {
    this.qualitydetailstable.forEach(item =>
      {
          this.qdCollection.push(
            {
             blp:  item.baselinE_PERFORMANCE,
             mp: item.measuremenT_PERIOD,
             pmt:item.qM_THRESHOLD,
             qmn:item.qualitY_METRIC_NUMBER,
             ti: item.qM_TIER_IND,
             measuremenT_PERIOD: item.measuremenT_PERIOD,
             qualitY_METRIC_NUMBER: item.qualitY_METRIC_NUMBER,
             qM_THRESHOLD: item.qM_THRESHOLD,
             baselinE_PERFORMANCE: item.baselinE_PERFORMANCE,
             qM_TIER_IND: item.qM_TIER_IND
           
            });
      });
      this.qualitydetailstable = this.qdCollection;
  }

  populate()
  {
      //Section - Contract Details
      this.contractdata = this.authservice.getcontractData();
      //console.log(this.contractdata);
      if(this.contractdata.contracT_ID === 0 )
      {
          this.toastr.error("Not a Valid Contract ID.");
          this.router.navigateByUrl("/reports?program=ContractList");
      }
      
      this.county = this.contractdata.county;
      this.market= this.contractdata.market;
      this.tinname= this.contractdata.tiN_NAME;
      this.tin= this.contractdata.tin;
      this.selectedState = this.contractdata.state;
      this.selectedEOC = this.contractdata.eoC_ID;
      if(this.contractdata.obH_IND === 0)
        {
          this.selectedIndicator= "OBH";

        }
        else        
        {

          this.selectedIndicator= "NON-OBH";

        }


      // this.selectedTIN= this.contractdata.tin;
      // this.selectedTINName= this.contractdata.tiN_NAME;
      if(this.contractdata.cntrcT_BEGIN != undefined)
      {
        this.csdmodel = this._parserFormatter.parse(this.contractdata.cntrcT_BEGIN.toString());
      }
      if(this.contractdata.cntrcT_END != undefined)
      {
        this.cedmodel = this._parserFormatter.parse(this.contractdata.cntrcT_END.toString());

      }
      this.lowoutliervalue= this.contractdata.loW_OUTLIER_VALUE;
      this.highoutliervalue= this.contractdata.higH_OUTLIER_VALUE;
      this.baselinecosttarget= this.contractdata.baselinE_COST_TARGET;
      this.gainsharingpercent= this.contractdata.gaiN_SHARING_PCT;
      this.sharedoppertunitypercent= this.contractdata.shareD_OPP_LIMIT_PCT;
      this.configversion= this.contractdata.confiG_VERSION;
      this.selectedPCDB=this.PCDBList.filter(item =>item.value === this.contractdata.precontractinG_DBNAME).map(item => item.value);
      if(this.selectedPCDB.length == 0)
        {
          this.selectedPCDB = "Not Found";
          this.isTextInputVisible=true;
          this.precontractdbname=this.contractdata.precontractinG_DBNAME;
        }
        // else
        // {
        //   this.selectedEOC= this.contractdata.precontractinG_DBNAME;
        // }

      this.algorithmversion= this.contractdata.algorithM_VERSION;
       //Reqd. to populte the MP dropdown based on the dates
      this.populateMPList();
      //Section - Cost Targets
      this.costtargettable = this.authservice.getcontractCTData();
      
      this.costtargettable.forEach(item => 
        {
            this.ctCollection.push(
              {
               lct: item.loW_COST_THRESHOLD,
               mp:item.measuremenT_PERIOD,
               loW_COST_THRESHOLD:item.loW_COST_THRESHOLD ,
               measuremenT_PERIOD:item.measuremenT_PERIOD
              });
        });
        this.costtargettable = this.ctCollection;

      //Section - Quality Details
      this.qualitydetailstable = this.authservice.getcontractQTData();
 
      this.qualitydetailstable.forEach(item =>
        {
            this.qdCollection.push(
              {
               blp:  item.baselinE_PERFORMANCE,
               mp: item.measuremenT_PERIOD,
               pmt:item.qM_THRESHOLD,
               qmn:item.qualitY_METRIC_NUMBER,
               ti: item.qM_TIER_IND,
               measuremenT_PERIOD: item.measuremenT_PERIOD,
               qualitY_METRIC_NUMBER: item.qualitY_METRIC_NUMBER,
               qM_THRESHOLD: item.qM_THRESHOLD,
               baselinE_PERFORMANCE: item.baselinE_PERFORMANCE,
               qM_TIER_IND: item.qM_TIER_IND
             
              });
        });
        this.qualitydetailstable = this.qdCollection;
  }

  addQualityDetailsGrid()
  {
    if(this.selectedMPQD == undefined || this.selectedMPQD=="" )
      {
        this.toastr.error(
          'Measurement Period: Required.'
        );
        return false;
      }

      if(this.checkDuplicateQualityDetail()==true)
        {
          this.toastr.error(
            'Measurement Period: Duplicate Entry Cant be added.'
          );
          return false;
        }


    if(this.qualitymetricnumber == undefined || this.qualitymetricnumber.value == 0 || this.qualitymetricnumber=="" )
      {
          this.toastr.error(
            'Quality Metric Number: Required.'
          );
          return false;
      }
      else{
        if(!this.validateDecimalNumberUsingMatch(this.qualitymetricnumber))
          {
            this.toastr.error(
              'Quality Metric Number: Invalid.'
            );
            return false;
          }
          
      }

      if(this.performancemeasurethreshold == undefined || this.performancemeasurethreshold.value == 0 || this.performancemeasurethreshold=="" )
      {
          this.toastr.error(
            'Performance Measure Threshold: Required.'
          );
          return false;
      }
      else{
        if(!this.validateDecimalNumberUsingMatch(this.performancemeasurethreshold))
          {
            this.toastr.error(
              'Performance Measure Threshold: Invalid.'
            );
            return false;
          }
          
      }


      

        if(this.performancemeasurethreshold == undefined || this.performancemeasurethreshold.value == 0 || this.performancemeasurethreshold=="" )
          {
              this.toastr.error(
                'Performance Measure Threshold: Required.'
              );
              return false;
          }
          else{
            if(!this.validateDecimalNumberUsingMatch(this.performancemeasurethreshold))
              {
                this.toastr.error(
                  'Performance Measure Threshold: Invalid.'
                );
                return false;
              }
              
          }

          if(this.baselineperformance == undefined || this.baselineperformance.value == 0 || this.baselineperformance=="" )
            {
                this.toastr.error(
                  'Baseline Performance: Required.'
                );
                return false;
            }
            else{
              if(!this.validateDecimalNumberUsingMatch(this.baselineperformance))
                {
                  this.toastr.error(
                    'Baseline Performance: Invalid.'
                  );
                  return false;
                }
                
            }

            if(this.selectedTier == undefined || this.selectedTier=="" )
              {
                this.toastr.error(
                  'Tiering Indicator: Required.'
                );
                return false;
              }


    //When all basic validations passed, checking Duplicate       
    
    
    if(this.checkDuplicateQualityDetail()==true)
      {
        this.toastr.error(
          'Measurement Period: Duplicate Entry Cant be added.'
        );
      }
      else
      {
        this.qdCollection.push(
          {
            qmn: this.qualitymetricnumber,
            mp: Number(this.selectedMPQD),
            pmt: this.performancemeasurethreshold,
            blp: this.baselineperformance,
            ti: this.selectedTier,
            measuremenT_PERIOD: null,
            qualitY_METRIC_NUMBER: null,
            qM_THRESHOLD: null,
            baselinE_PERFORMANCE: null,
            qM_TIER_IND: null
          
          });
          this.measurementperiodQD="";
          this.qualitymetricnumber="";
          this.performancemeasurethreshold="";
          this.baselineperformance="";
          this.selectedMPQD="";
          this.selectedTier="";
      }
    return true;
  }

  qualitydetailstable = this.qdCollection;

  onRemoveQD(qualitydetail){
    let index = this.qualitydetailstable.indexOf(qualitydetail);
    this.qualitydetailstable.splice(index, 1);
    }


   checkDuplicateCostTarget()
   {
    var found = false;
    this.ctCollection.forEach(item => 
    {
      if(item.mp === Number(this.selectedMPCT))
      {
         found = true;
      }
    });
    return found;
   }

   checkDuplicateQualityDetail()
   {
    var found = false;
    this.qdCollection.forEach(item => 
    {
      if(item.mp === Number(this.selectedMPQD) && (item.qmn === Number(this.qualitymetricnumber) || item.qmn === this.qualitymetricnumber))
      {
         found = true;
      }
    });
    return found;
   }

   costtargettable = this.ctCollection;

   addCostTargetGrid()
  {
    if(this.selectedMPCT == undefined || this.selectedMPCT=="" )
      {
        this.toastr.error(
          'Measurement Period: Required.'
        );
        return false;
      }

      if(this.checkDuplicateCostTarget()==true)
      {
        this.toastr.error(
          'Measurement Period: Duplicate Entry Cant be added.'
        );
        return false;
      }
    
    
  
      if(this.lowcostthreshold == undefined || this.lowcostthreshold.value == 0 || this.lowcostthreshold=="" )
      {
          this.toastr.error(
            'Low Cost Threshold: Required.'
          );
          return false;
      }
      else{
        if(!this.validateDecimalNumberUsingMatch(this.lowcostthreshold))
          {
            this.toastr.error(
              'Low Cost Threshold: Invalid.'
            );
            return false;
          }
          
      }
    if(this.checkDuplicateCostTarget()==true)
    {
      this.toastr.error(
        'Measurement Period: Duplicate Entry Cant be added.'
      );
    }
    else
    {
      this.ctCollection.push(
        {
          mp: Number(this.selectedMPCT),
          lct: this.lowcostthreshold,
          loW_COST_THRESHOLD:null,
          measuremenT_PERIOD:null
        });
        this.selectedMPCT="";
        this.lowcostthreshold="";
    }


    return true;
  }
  
  onRemove(costtarget){
    let index = this.costtargettable.indexOf(costtarget);
    this.costtargettable.splice(index, 1);
    }


    ngAfterViewInit()
    {

    }

    /*Validation*/

    validateContractbasis()
    {
      // console.log("Start of validateContractbasis()");
      // console.log("selectedIndicator:", this.selectedIndicator);
      // console.log(this.selectedState);

 
      if(this.selectedState == undefined || this.selectedState=="" )
        {
          this.toastr.error(
            'State: Required.'
          );
          return false;
        }

        // if(this.selectedTIN == undefined || this.selectedTIN=="" )
        if(this.tin == undefined || this.tin=="" )
          {
            this.toastr.error(
              'TIN: Required.'
            );
            return false;
          }
  

          // if(this.selectedTINName == undefined || this.selectedTINName=="" )
          if(this.tinname == undefined || this.tinname=="" )
            {
              this.toastr.error(
                'TIN Name: Required.'
              );
              return false;
            }

            if(this.tin.length < 9|| this.tin.length >9)
              {
                this.toastr.error("TIN length should be 9 characters");
                return false;
              }
    

          
            if(this.selectedEOC == undefined || this.selectedEOC=="" )
              {
                this.toastr.error(
                  'EOC: Required.'
                );
                return false;
              }
      

            if(this.lowoutliervalue==undefined || this.lowoutliervalue.length == 0)
              {
                this.toastr.error(
                  'Low outlier Value: Required.'
                );
                return false;
              }
              else{
                if(!this.validateDecimalNumberUsingMatch(this.lowoutliervalue))
                  {
                    this.toastr.error(
                      'Low outlier Value: Invalid.'
                    );
                    return false;
                  }
                
              }
            

              if(this.highoutliervalue==undefined || this.highoutliervalue.length == 0
                || this.highoutliervalue == 0)
                {
                  this.toastr.error('High outlier Value: Required.');  
                  return false;
                }
              else
              {
                if(!this.validateDecimalNumberUsingMatch(this.highoutliervalue))
                  {
                    this.toastr.error(
                      'High Outlier value: Invalid  .'
                    ); 
                    return false;
                  }
              }
              if(this.lowoutliervalue>this.highoutliervalue)
                {
                  this.toastr.error('Higher Outlier value should be greater than Lower Outlier value. ');
                  return false;
                }
                if(this.baselinecosttarget==undefined || this.baselinecosttarget.length == 0
                  || this.baselinecosttarget == 0)
                  {
                    this.toastr.error(
                      'BaseLine Cost Target: Required.'
                    ); 
                    return false;
                  }
                  else{
                    if(!this.validateDecimalNumberUsingMatch(this.baselinecosttarget))
                      {
                        this.toastr.error(
                          'BaseLine Cost Target: Invalid  .'
                        ); 
                        return false;
                      }
                  }

                  if(this.gainsharingpercent==undefined || this.gainsharingpercent.length == 0
                    || this.gainsharingpercent == 0
                  )
                    {
                      this.toastr.error(
                        'Gain Sharing %: Required.'
                      ); 
                      return false;
                    }
  
                    if(this.sharedoppertunitypercent==undefined || this.sharedoppertunitypercent.length == 0)
                      {
                        this.toastr.error(
                          'Shared Oppertunity %: Required.'
                        ); 
                        return false;
                      }
                   
                    if(this.contractStartDate==undefined || this.contractStartDate=="")
                    {
                        this.toastr.error('Contract Start Date: Required.');  
                        return false;
                    }
                    if(this.contractEndDate==undefined || this.contractEndDate=="")
                    {
                          this.toastr.error('Contract End Date: Required.');  
                          return false;
                    }
                    else
                    {
                      var endDate = new Date(this.contractEndDate);
                      var startDate = new Date(this.contractStartDate);
  
                      if(startDate > endDate)
                      {
                          this.toastr.error('Contract End Date should not be Greater than Contract Start Date.'); 
                          return false;
                      }
  
                    }
     
      if(this.selectedIndicator == undefined || this.selectedIndicator=="" )
        {
          this.toastr.error(
            'Indicator: Required.'
          ); 
          return false;
        }

        if(this.editmode===false)
        {
          if(this.selectedIndicator=="OBH")
          {
             this.selectedIndicator ="1";
          }
          else
          {
            this.selectedIndicator ="0";
          }
        }


        if((this.selectedPCDB == undefined || this.selectedPCDB=="")
        || (this.selectedPCDB ==="Not Found" && (this.precontractdbname ==undefined || this.precontractdbname == "")))
          {
            this.toastr.error(
              'Pre Contract DB Name: Required.'
            ); 
            return false;
          }
  

          
        if(this.algorithmversion == undefined || this.algorithmversion=="" )
          {
            this.toastr.error(
              'Algorithm Version: Required.'
            ); 
            return false;
          }
  

        //IF all passed return true
        return true;
    }

    validateCostTarget()
    {
      //console.log("validateCostTarget() called");
      var checkPassed:boolean  = false;
      // console.log('mpCollection: ',this.mpCollection);
      // console.log('ctcollection: ',this.ctCollection);

      //Each Measurement period should be available in Grid
      for(var i=0; i < this.mpCollection.length;i++)
      {
        checkPassed = false;
        for(var j=0; j< this.ctCollection.length;j++)
        {

          if(this.mpCollection[i].code== this.ctCollection[j].mp)
          {
            checkPassed =true;
          }
        }


      // //When item not found Break it.
      if(checkPassed==false)
      {
            //console.log("Validation Result is " , checkPassed, "i:", i, "j:", j);
            this.toastr.error('Please provide Cost Target configuration for each Measurement period.');
            return checkPassed;
      }
    }
      return checkPassed;
    }

    validateQualityDetails()
    {
     //console.log("validateQualityDetails() called");
     var checkPassed:boolean  = false;
      for(var i=0; i < this.mpCollection.length;i++)
        {
        checkPassed = false;
        for(var j=0; j < this.qdCollection.length;j++)
        {
        if(this.mpCollection[i].code== this.qdCollection[j].mp)
             {
               //console.log("Matched", this.mpCollection[i].code, this.qdCollection[j].mp);
               checkPassed =true;
               //console.log("Value of Checkpassed:", checkPassed);
             }
        
        }
        
        //When item not found Break it.
         if(checkPassed==false)
         {
               //console.log("Validation Result is " , checkPassed, "i:", i, "j:", j);
               this.toastr.error('Please provide Quality Details for each Measurement period.');
               return checkPassed;
         }
      } 
     
     //console.log("validateCostTarget. NetResult", checkPassed);
       return checkPassed;
    }



    integerOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) 
        {
        return false;
      }
      return true;
  
    }

    alphanumeric_DOT_Only(event): boolean {

      var regex = new RegExp("^[a-zA-Z0-9.]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
         event.preventDefault();
         return false;
      }
      return true;
    }


    decimalOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      {
        return false;
      }
    return true;
    }

    valuecheck(){
      if (this.highoutliervalue > 0 &&  this.highoutliervalue < this.lowoutliervalue) {
        this.toastr.error('Higher Outlier value should be greater than Lower Outlier value..');
        return false;
      }
      return true;
    }

    alphabetOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
        if (event.keyCode >= 65 && event.keyCode <= 90)
        {
          return true;
        }
        else if (event.keyCode >= 97 && event.keyCode <= 122)
          {
            return true;
          }
        else
        {
          return false;
        }
    }


    validateDecimalNumberUsingMatch(input) 
    {
      if(isNaN(input))
      {
          return false;
      }
      return true;
    } 
    
    LoadStartDate()
    {
     
      this.contractStartDate = this._parserFormatter.format(this.csdmodel);
       this.storage_token.set('contractStartDate', this.contractStartDate);
    }

    LoadMP()
    {
      
    }

    LoadEndDate()
    { 
      // console.log("LoadEndDate() called");
      
      //Clear Measuremenet Period Dropdown 
      for(var i = 0; i < this.mpArrayObject.length ;i++)
      {
        this.mpArrayObject.splice(0);
       
      }
      //Clear QualityDetails Grid
      for(var i = 0; i < this.qualitydetailstable.length ;i++)
      {
          this.qualitydetailstable.splice(0);
      }
      //Clear CostTargets Grid
      for(var i = 0; i < this.costtargettable.length ;i++)
      {
            this.costtargettable.splice(0);
      }
      this.populateMPList();
      return true;
    }

    populateMPList()
    {
      // console.log("populateMPList() ",this.mpArrayObject);
      this.contractStartDate = this._parserFormatter.format(this.csdmodel);
      this.contractEndDate = this._parserFormatter.format(this.cedmodel);

      if(this.contractStartDate!=undefined && this.contractEndDate != undefined)
        {
          var stDate = new Date(this.contractStartDate);
          var endDt = new Date(this.contractEndDate);

          //console.log("stDate", stDate);
          //console.log("endDt", endDt);
          
          if(stDate > endDt)
          {
                this.toastr.error('Contract End Date should be Greater than Contract Start Date.'); 
                this.contractEndDate="";
          }
          var diff = Math.abs(endDt.getTime() - stDate.getTime());
          var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
          var yrs = (diffDays / 365) + 1;

          // console.log("diffDays - ", diffDays);
          // console.log("yrs", yrs);
          this.mpCollection.splice(0);
          this.mpArrayObject.splice(0);

          for(var i = 1; i < yrs ;i++)
          {
            this.mpArrayObject.push({
              code: i,
              value: i
            });
    
            this.mpCollection.push(
              {
                code: i,
                value: i
              }
            );
          }
          this.MPList = this.mpArrayObject;
        }
    }


    SelectState(_state:string)
    {
      this.selectedState = _state;
      this.storage_token.set('statecode', this.state);    
    }

    SelectProvider(_value:string)
    {
      this.selectedProvider = _value;
      this.storage_token.set('provider', this.provider);    
    }

    // SelectTIN(_value:string)
    // {
    //   this.selectedTIN = _value;
    //   this.storage_token.set('tin', this.tin);    
    // }

    // SelectTINName(_value:string)
    // {
    //   this.selectedTINName = _value;
    //   this.storage_token.set('tinname', this.tinname);    
    // }
    SelectEOC(_value:string)
    {
      this.selectedEOC = _value;
      this.storage_token.set('eoc', this.eoc);    
    }
    selectPCDB(_value:string)
    {
      if (_value == "Not Found")
        {
          this.isTextInputVisible = true;
        }
        else
        {
          this.precontractdbname="";
          this.isTextInputVisible = false;
        }
        this.selectedPCDB = _value;
      this.storage_token.set('pcdb', this.pcdb);    
    }
    SelectIndicator(_value:string)
    {
      this.selectedIndicator = _value;
      this.storage_token.set('indicator', this.indicator);    
    }

    SelectMPCT(_value:string)
    {
      this.selectedMPCT = _value;
      this.storage_token.set('mpct', this.measurementperiod);    
    }

    SelectMPQD(_value:string)
    {
      
      this.selectedMPQD = _value;
      this.storage_token.set('mpqd', this.measurementperiodQD);    
    }

    SelectTiering(_value:string)
    {
      
      this.selectedTier = _value;
      this.storage_token.set('mpti', this.selectedTier);    
    }

    LoadEoc()
    {
      this.storage_token.set('eoc', this.eoc);
      //console.log("Eoc added to Storage", this.eoc)
    }

    LoadPCDB()
    {
      this.storage_token.set('pcdb', this.pcdb);
    }

    saveContract()
    {      

      if(this.validateContractbasis()===true && 
        this.validateCostTarget() ===true && 
        this.validateQualityDetails() ===true)
      {
        if(this.editmode===false)
        {
          this.toastr.success("New Contract Request: Submitted Succesfully.");
        }
        else
        {
          this.toastr.success("Contract Revision Request: Submitted Succesfully.");
        }
      
        //Converting SelctedIndicator value to Int to handle in Editmode.
        if(this.editmode === true)
        {
            if(this.selectedIndicator=== "OBH")
              {
                  this.selectedIndicator ="1";
              }else
              {
                this.selectedIndicator ="0";
      
              }
        }
        if (this.selectedPCDB==="Not Found")
        {
          this.selectedPCDB = this.precontractdbname.toString();
        }
        this.selectedPCDB = this.selectedPCDB.toString();

        this.authservice.SaveContractDetails(this.tin, this.selectedState, 
        this.market, this.county, this.selectedEOC, null, this.contractStartDate,this.contractEndDate,
        this.lowoutliervalue,this.highoutliervalue,this.baselinecosttarget,this.gainsharingpercent,
        this.sharedoppertunitypercent, this.algorithmversion, this.selectedPCDB, this.selectedIndicator,
        this.tinname,null , this.configversion , this.id, this.editmode).
        subscribe((response: any) => 
        {
          this.storage_token.set('CONTRACT_ID', response.contracT_ID);
          this.contractID = response.contracT_ID;
          if(Number(this.storage_token.get('CONTRACT_ID')) > 0 )
            {
             this.ctCollection.forEach(item => 
               {
                   if(item.mp > 0)
                   {
                     this.authservice.SaveCostTargets(item.mp, item.lct, "0", this.editmode).
                     subscribe((response: any) => 
                     {
                       var costTargetResult =response.result;
                     });
                   }
               });
 
               this.qdCollection.forEach(qd => 
                 {
                     if(qd.mp > 0)
                     {
                       this.authservice.SaveQualityDetails
                       (qd.qmn, qd.mp, qd.blp, qd.pmt,this.selectedEOC,qd.ti,0,0, this.contractStartDate,
                         this.configversion,this.editmode).
                         subscribe((response: any) => 
                         {
                           var costTargetResult = response.result;
                          });
         
                     }
                 });
             if(this.editmode===false)
             {
               this.toastr.success("New Contract: Saved Succesfully.");
             }
             else{
               this.toastr.success("Contract Revision: Saved Succesfully.");
 
             }
             //Reset the values
             this.id = 0;
             this.storage_token.set("CONTRACT_ID", 0);
             this.editmode = false;
             this.router.navigateByUrl("/reports?program=ContractList");
             
 
             }
           else{
           this.toastr.error("Unable to Save Contract");
           }
        });

       
        // setTimeout(() => 
        //   {
          // if(Number(this.storage_token.get('CONTRACT_ID')) > 0 )
          //  {
          //   this.ctCollection.forEach(item => 
          //     {
          //         if(item.mp > 0)
          //         {
          //           this.authservice.SaveCostTargets(item.mp, item.lct, "0", this.editmode).
          //           subscribe((response: any) => 
          //           {
          //             var costTargetResult =response.result;
          //           });
          //         }
          //     });

          //     this.qdCollection.forEach(qd => 
          //       {
          //           if(qd.mp > 0)
          //           {
          //             this.authservice.SaveQualityDetails
          //             (qd.qmn, qd.mp, qd.blp, qd.pmt,this.selectedEOC,qd.ti,0,0, this.contractStartDate,
          //               this.configversion,this.editmode).
          //               subscribe((response: any) => 
          //               {
          //                 var costTargetResult = response.result;
          //                });
        
          //           }
          //       });
          //   if(this.editmode===false)
          //   {
          //     this.toastr.success("New Contract: Saved Succesfully.");
          //   }
          //   else{
          //     this.toastr.success("Contract Revision: Saved Succesfully.");

          //   }
          //   //Reset the values
          //   this.id = 0;
          //   this.storage_token.set("CONTRACT_ID", 0);
          //   this.editmode = false;
          //   this.router.navigateByUrl("/reports?program=ContractList");
            

          //   }
          // else{
          // this.toastr.error("Unable to Save Contract");
          // }
          // }, 11000 );
       
      }
      else
      {
          if(!this.validateContractbasis())
          {
            this.toastr.error("Unable to submit.  Please provide all Required values in Contract Basics.");
          }
          else if(!this.validateCostTarget())
          {
            this.toastr.error("Unable to submit.  Please provide all Required values in Cost Targets.");
          }
          else if(!this.validateQualityDetails())
          {
              this.toastr.error("Unable to submit.  Please provide all Required values in Quality Details.");
          }

          
      }
    };

    // SaveQualityDetails()
    // {
    //   this.qdCollection.forEach(qd => 
    //     {
    //         if(qd.mp > 0)
    //         {
    //           var costTargetResult = this.authservice.SaveQualityDetails
    //           (qd.qmn, qd.mp, qd.blp, qd.pmt,this.selectedEOC,0,0,0, this.contractStartDate,this.configversion);

    //         }
    //     });
    // }

}



