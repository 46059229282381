import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';


interface IContractAdminList {
  contract_id: string,
  client_id: string,
  tin: string,
	state: string,
	county: string,
	eoc_id: string,
  contract_stat:string,
  contract_begin:string,
  contract_end:string,
  
}


@Component({
  selector: 'app-contractlist',
  templateUrl: './contractlist.component.html',
  styleUrls: ['./contractlist.component.css']

})
export class ContractlistComponent {
  

  
  public ContractData_List:any;
  public ccReportList:any;
  public reports: IContractAdminList[];
	page = 1;
	pageSize = 25;
	collectionSize =   25;
  public cad:string="ContractAdmin";
  public idSelectedfor_delete =0;
  
  titlemodel = {
    columns: [
	 {label: 'Action', id: 'ACTION', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'CONTRACT #', id: 'CONTRACT_ID', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'CLIENT ID', id: 'CLIENT_ID', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'TIN', id: 'TIN', datatype: 'text', style: { 'min-width': '5%','text-align': 'left' }},
	 {label: 'STATE', id: 'STATE', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'COUNTY', id: 'COUNTY', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'EOC ID', id: 'EOC_ID', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'CONTRACT FROM', id: 'CONTRACT PERIOD FROM', datatype: 'text', style: { 'text-align': 'left' }},
	 {label: 'CONTRACT TO', id: 'CONTRACT PERIOD TO', datatype: 'text', style: { 'text-align': 'left' }},
   {label: 'STATUS', id: 'CONTRACT_STATUS', datatype: 'text', style: { 'text-align': 'left' }},
	]
}


constructor(
  public authservice: AuthorizationService, 
  public localStorage:LocalStorageService,
  private storage_token: LocalStorageService,
  config: NgbModalConfig,
  private modalService: NgbModal,
  private toastr: ToastrService,
  private router: Router,
) {
  // customize default values of modals used by this component tree
  config.backdrop = 'static';
  config.keyboard = false;
}



ngOnInit(): void 
  {
	  //Actual API call 
    this.authservice.GetContractAdminList().
    subscribe((response: any) =>        
      {        
         //this.httpResCheck.CheckHttpResponseNotify(response);        
         this.ContractData_List = response.body.$values;
         this.collectionSize = this.ContractData_List.length;
        this.refreshReport();      
      });
    //this.LoadData();
  }


  // private LoadData()
  // {
  //   setTimeout(() => 
  //     {
  //       this.ccReportList = this.authservice.getcontractdataList();
  //       this.collectionSize = this.ccReportList.length;
  //       this.refreshReport();
  //     }, 3000 );
  // }


  refreshReport() {
    this.ccReportList=this.ContractData_List;
    if(this.ccReportList != undefined)
    {
        this.ccReportList = this.ccReportList.map((item, i) => ({ id: i + 1, ...item })).slice(
          (this.page - 1) * this.pageSize,
          (this.page - 1) * this.pageSize + this.pageSize,
        );
      }
    } 

    deleteContract()
    {
      this.authservice.DeleteContractData(this.idSelectedfor_delete);
      this.toastr.success("Contract ID:"+this.idSelectedfor_delete+ " deactivated Succesfully.");
      //Actual API call to Refresh the Data
      this.authservice.GetContractAdminList().subscribe((response: any) =>        
        {        
           //this.httpResCheck.CheckHttpResponseNotify(response);        
           this.ContractData_List = response.body.$values;
           this.collectionSize = this.ContractData_List.length;
          this.refreshReport();      
        });
      // this.LoadData();
    }
    

    open(content,id:any) {
      this.idSelectedfor_delete = id;
      this.modalService.open(content);
    }
}
